import React from 'react';
import './contact.css';
// import Button from './button';
import Form from './form';

const Contact = () => {
  return (
    <section className="message">
      <div className="row cat">
        <div className="col span-1-of-2 contact-aside-box">
          <div className="blue-wrapper">
            <div className="img-wrapper">
              <img
                className="contact-img"
                src={require('../images/telephone.svg')}
                alt="Contact"
              ></img>
            </div>

            <div className="row">
              <div className="col span-1-of-2 contact-mob-view">
                <div className="contact-phone-col">
                  <p className="icon-title">Phone</p>
                  <p className="icon-para">+91-8921265968</p>
                  <p className="icon-para">+91-9746163694</p>
                </div>
              </div>

              <div className="row email-row contact-mob-view">
                <div className="col span-1-of-2 contact-mob-view">
                  <p className="icon-title">Email</p>
                  <p className="icon-para">hello@firstbytedigital.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col span-1-of-2 form-box">
          <p className="form-title">Send us a message</p>
          <Form />
        </div>
      </div>
    </section>
  );
};

export default Contact;
