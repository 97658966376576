import React from 'react';
import './card3.css';

const Card3 = (props) => {
  return (
    <div>
      <img
        className="tech-img"
        src={props.source}
        style={{ width: props.imgWidth, height: props.imgHeight }}
      ></img>
      <p className="tech-img-title">{props.title}</p>
    </div>
  );
};

export default Card3;
