import React from 'react';
import './contact-banner.css';

const ContactBanner = () => {
  return (
    <section className="blue-box">
      <p className="contact-title">Contact us</p>
      <div className="row">
        <p className="contact-para">
          Need a technology solution? Get in touch with us.
        </p>
      </div>
    </section>
  );
};

export default ContactBanner;
