import React from 'react';
import './card.css';

const Card = (props) => {
  return (
    <div className="card-wrap">
      <img className="dcp-img" src={props.source} alt="" />
      <p className="dcp-para-title">{props.title}</p>
      <p className="dcp-para">{props.des}</p>
    </div>
  );
};

export default Card;
