import React from 'react';
import './card2.css';
import Button from './button';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Card2 = (props) => {
  return (
    <div className="card-wrap">
      <img className="dcp-img" src={props.source}></img>
      <p className="dcp-para-title">{props.title}</p>
      <p className="dcp-para">{props.des}</p>
    </div>
  );
};

export default Card2;
