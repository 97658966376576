import React from 'react';
import '../components/grid.css';

import Layout from '../components/layout';
import '../components/index.css';

import Button from '../components/button';
import Card from '../components/card';
import Card2 from '../components/card2';
import Card3 from '../components/card3';
import ContactBanner from '../components/contact-banner';
import Contact from '../components/contact';
import SEO from '../components/seo';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="row hero">
      <div className="col span-1-of-2 boxx-hero">
        <h1 className="title animated fadeIn">
          We transform your ideas into delightful digital experiences
        </h1>
        <p className="hero-para">
          We are a team of highly skilled software developers and creative
          geniuses, ready to build the next big application for you.
        </p>
        <div className="hero-inv-div">
          <img
            className="hero-img-invisible"
            src={require('../images/human.svg')}
            alt="Home"
          ></img>
        </div>

        <div className="button-wrap">
          <AnchorLink to="#contact">
            <Button buttonStyle="btn--primary--solid">Get started</Button>
          </AnchorLink>
        </div>
      </div>
      <div className="col span-1-of-2">
        <img
          className="hero-img"
          src={require('../images/human.svg')}
          alt="Home"
        ></img>
      </div>
    </div>

    <section className="intro">
      <div className="row cat">
        <div className="col span-1-of-2">
          <div className="home-img">
            <img
              className="meet"
              src={require('../images/me2.svg')}
              alt="Home"
            ></img>
          </div>
        </div>

        <div className="col span-1-of-2 box-intro">
          <p className="intro-title">What we do?</p>
          <p className="intro-exp">
            We design and develop custom web applications and mobile
            applications for our clients.
          </p>
          <div className="intro-inv-div">
            <img
              className="intro-img-invisible"
              src={require('../images/me2.svg')}
              alt="Home"
            ></img>
          </div>
          <div className="button-wrap">
            <AnchorLink to="#our-services">
              <Button buttonStyle="btn--primary--outline">Learn more</Button>
            </AnchorLink>
          </div>
        </div>
      </div>
    </section>

    <section className="dcp">
      <div className="row cat">
        <p className="dcp-title">We understand</p>
      </div>

      <div className="row">
        <div className="col span-1-of-3 boxx">
          <Card
            title="Design"
            des="We adore beauty. That reflects in the products that we build. We believe that you and your users are entitled for a premium digital experience."
            source={require('../images/design.svg')}
          ></Card>
        </div>

        <div className="col span-1-of-3 boxx">
          <Card
            title="Code"
            des="We work with the latest and greatest in the tech world to build products that are robust and responsive."
            source={require('../images/code.svg')}
          ></Card>
        </div>

        <div className="col span-1-of-3 boxx">
          <Card
            title="Product"
            des="We take a holistic approach to app development. We are striving to create lasting impressions. That takes expertise in every aspect of the development process."
            source={require('../images/pro.svg')}
          ></Card>
        </div>
      </div>
    </section>

    <section className="idea">
      <div className="row cat">
        <div className="col span-1-of-2 box-idea">
          <p className="idea-title">
            You have an idea and need help in realising it?
          </p>
          <p className="idea-sub">Talk to us</p>
          <p className="idea-para">
            We will understand your product and leverage the right technologies
            and thoughtful design to realize it.
          </p>

          <img
            className="idea-inv"
            src={require('../images/qstn.svg')}
            alt="Home"
          ></img>
          <div className="button-wrap">
            <AnchorLink to="#contact">
              <Button buttonStyle="btn--primary--solid">Get started</Button>
            </AnchorLink>
          </div>
        </div>
        <div className="col span-1-of-2 qstn-box">
          <div className="home-img">
            <img
              className="idea-img"
              src={require('../images/qstn.svg')}
              alt="Home"
            ></img>
          </div>
        </div>
      </div>
    </section>

    <section className="services" id="our-services">
      <div className="row">
        <p className="dcp-title">Our services</p>

        <p className="serv-para">
          We build custom web and mobile applications that are feature rich and
          engaging. We also provide cloud services including management of
          hosted applications, databases, storage and virtual private servers.
        </p>
      </div>

      <div className="row">
        <div className="col span-1-of-3 boxx">
          <Card2
            title="Web development"
            des="Having a digital presence is key to the success of any business. We help you create your space on the web."
            source={require('../images/web.svg')}
          ></Card2>
          <AnchorLink to="/services#web-dev">
            <Button buttonStyle="btn--primary--borderless">Read more</Button>
          </AnchorLink>
        </div>

        <div className="col span-1-of-3 boxx">
          <Card2
            title="Mobile app development"
            des="Build iOS and Android apps out of your ideas. We will provide full lifecycle support."
            source={require('../images/mob.svg')}
          ></Card2>
          <AnchorLink to="/services#mob-dev">
            <Button buttonStyle="btn--primary--borderless">Read more</Button>
          </AnchorLink>
        </div>

        <div className="col span-1-of-3 boxx">
          <Card2
            title="Software consultancy"
            des="We guide you in the right direction to transform your business digitally."
            source={require('../images/cloud.svg')}
          ></Card2>
          <AnchorLink to="/services#cloud">
            <Button buttonStyle="btn--primary--borderless">Read more</Button>
          </AnchorLink>
        </div>
      </div>
    </section>

    <section className="tech">
      <div className="row">
        <p className="tech-title">Our tech</p>
      </div>

      <div className="row tech-row">
        <div className="col span-1-of-3 boxx-tech">
          <Card3
            source={require('../images/nodejs.png')}
            title="NodeJS"
            imgWidth={50}
            imgHeight="auto"
          ></Card3>
        </div>

        <div className="col span-1-of-3 boxx-tech">
          <Card3
            source={require('../images/reactt.png')}
            title="ReactJS"
            imgWidth={55.41}
            imgHeight="auto"
          ></Card3>
        </div>

        <div className="col span-1-of-3 boxx-tech">
          <Card3
            source={require('../images/react-native.png')}
            title="React Native"
            imgWidth={87.5}
            imgHeight="auto"
          ></Card3>
        </div>
      </div>

      <div className="row tech-row-small">
        <div className="col span-1-of-2 boxx-tech">
          <Card3
            source={require('../images/nodejs.png')}
            title="NodeJS"
            imgWidth={50}
            imgHeight="auto"
          ></Card3>
        </div>

        <div className="col span-1-of-2 boxx-tech">
          <Card3
            source={require('../images/reactt.png')}
            title="ReactJS"
            imgWidth={55.41}
            imgHeight="auto"
          ></Card3>
        </div>
      </div>

      <div className="row tech-row-small">
        <div className="col span-1-of-2 boxx-tech">
          <Card3
            source={require('../images/react-native.png')}
            title="React Native"
            imgWidth={87.5}
            imgHeight="auto"
          ></Card3>
        </div>

        <div className="col span-1-of-2 boxx-tech">
          <Card3
            source={require('../images/mongodb.png')}
            title="MongoDB"
            imgWidth={72.2}
            imgHeight="auto"
          ></Card3>
        </div>
      </div>

      <div className="row tech-row-small">
        <div className="col span-1-of-2 boxx-tech">
          <Card3
            source={require('../images/postgresql.png')}
            title="PostgreSQL"
            imgWidth={48.78}
            imgHeight="auto"
          ></Card3>
        </div>

        <div className="col span-1-of-2 boxx-tech">
          <Card3
            source={require('../images/graphql.png')}
            title="GraphQL"
            imgWidth={43.9}
            imgHeight="auto"
          ></Card3>
        </div>
      </div>

      <div className="row tech-row">
        <div className="col span-1-of-3 boxx-tech">
          <Card3
            source={require('../images/mongodb.png')}
            title="MongoDB"
            imgWidth={72.2}
            imgHeight="auto"
          ></Card3>
        </div>

        <div className="col span-1-of-3 boxx-tech">
          <Card3
            source={require('../images/postgresql.png')}
            title="PostgreSQL"
            imgWidth={48.78}
            imgHeight="auto"
          ></Card3>
        </div>

        <div className="col span-1-of-3 boxx-tech">
          <Card3
            source={require('../images/graphql.png')}
            title="GraphQL"
            imgWidth={43.9}
            imgHeight="auto"
          ></Card3>
        </div>
      </div>
    </section>

    <section className="contactt" id="contact">
      <ContactBanner />
      <Contact />
    </section>
  </Layout>
);

export default IndexPage;
