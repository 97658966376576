import React, { Component } from 'react';
import './form.css';
import Button from './button';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class Form extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      message: '',
      nameError: false,
      emailError: false,
      messageError: false,
      isSuccess: false,
    };
  }
  handleNameChange = (e) => {
    this.setState({ name: e.target.value, nameError: false, isSuccess: false });
  };

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      emailError: false,
      isSuccess: false,
    });
  };
  handleMessageChange = (e) => {
    this.setState({
      message: e.target.value,
      messageError: false,
      isSuccess: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = this.state;
    if (!name) {
      this.setState({ nameError: true, isSuccess: false });
    } else if (!email) {
      this.setState({ emailError: true, isSuccess: false });
    } else if (!message) {
      this.setState({ messageError: true, isSuccess: false });
    } else {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', name, email, message }),
      })
        .then(() =>
          this.setState({ isSuccess: true, name: '', email: '', message: '' })
        )
        .catch((error) => alert(error));

      e.preventDefault();
    }
  };

  render() {
    return (
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="input-wrap">
          <label htmlFor="name">Your name</label>
          <input
            id="name"
            value={this.state.name}
            onChange={this.handleNameChange}
            placeholder="Enter your name"
            name="name"
          />
          {this.state.nameError ? <span>Please enter your name</span> : null}
        </div>

        <div className="input-wrap">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            value={this.state.email}
            onChange={this.handleEmailChange}
            placeholder="Enter your email"
            name="email"
          />
          {this.state.emailError ? <span>Please enter your email</span> : null}
        </div>

        <div className="input-wrap">
          <label htmlFor="name">Your message</label>
          <input
            id="message"
            value={this.state.message}
            onChange={this.handleMessageChange}
            placeholder="Enter your message"
            name="message"
          />
          {this.state.messageError ? <span>Please enter message</span> : null}
        </div>
        {this.state.isSuccess && (
          <p className="message-success">
            {' '}
            Thanks for reaching out. We will get back to you at the earliest.
          </p>
        )}
        <div className="btn-wrap">
          <Button
            buttonStyle="btn--primary--fullwidth"
            onClick={this.handleSubmit}
          >
            SEND
          </Button>
        </div>
      </form>
    );
  }
}

export default Form;
